@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container {
  max-width: 960px;
}

ul {
  padding-left: 25px !important;
}

.privacy {
  font-size: 0.7rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #49210a;
}

.cv {
  font-size: 0.9rem;
}

.alert-text {
  margin-bottom: 0 !important;
}

.confirmation-data {
  background-color: #FFF;
  padding: 25px;
  border: 1px solid #49210a47;
  border-radius: 15px;
}

input.ng-dirty.ng-invalid {
  border: 2px solid #FC6161;
}

input.ng-dirty.ng-valid {
  border: 2px solid #0F8BFD;
}


p-calendar .p-button.p-button-icon-only {
  width: 1.9rem;
  padding: 0.2rem 0;
}

.custom-dialog-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #252636;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1rem 1.714rem 1.714rem 1.714rem;
}

.p-float-label-custom {
  position: relative;
  display: inline;
  width: 100%;
}

.p-float-label-custom label {
  position: absolute;
  pointer-events: none;
  top: 65%;
  margin-top: -1.1rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  left: 1.0rem;
  color: #868c9b;
}

.custom-p-filled-label .p-float-label .p-inputwrapper-filled~label {
  display: none !important;
}

p-dropdown.w-100 .p-dropdown.p-component {
  width: 100% !important;
}